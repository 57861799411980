import * as React from "react"
import Layout from "../components/Layout"

import styled from 'styled-components'
import { SEO } from "../components/Seo"

import { StaticImage } from 'gatsby-plugin-image';

import { Link } from 'gatsby';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import Wyniki from "../assets/URDB-WYNIKI.pdf"
import Wyniki24 from "../assets/WynikiURDB24.pdf"

const GalleryPage = styled.div`
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 30px 0px;
`
//COL
const ImageBoxStyled = styled.p`
  width: 90%;
  border: solid white 2px;
  ${({theme})=> theme.media.tablet}{
          width: 40%;
      }
`

const Title =styled.h1`
    font-weight: 100;
    text-align: center;
    margin: auto;
    padding: 40px 0px;
`
const ImageHeroStyle = styled.a`
    display: grid;
    text-decoration: none;
    padding: 30px 0;
    cursor: pointer;


    .img{
        grid-area: 1/1;
        /* width: 100%; */
        /* height: 300px; */
        filter: brightness(75%);
        -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);   
    }
    

    div{
        grid-area: 1/1;
        position: relative;

        place-items: center;
        display:grid;
        text-align: center;
      
        h1{
            color: white;
            font-size: 2rem;
            font-weight: 400;
            /* text-transform: uppercase; */
            font-family: "Roboto", sans-serif;

        }
    }
    ${({theme})=> theme.media.tablet}{
      .img{
        height: 350px; 
      }
    } 

`

const Galeria = () => {
  
  return (
   
    <Layout>
      
      <Title>Ultra Race Dolina Bugu Galeria</Title>

              <GalleryPage>
                <ImageBoxStyled>
                <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>

                    <ImageHeroStyle href={Wyniki} title="Wyniki URDB 2023" className='linkStyle'>
                      <StaticImage 
                          placeholder="blurred"
                          alt="more ultra race"
                          // transformOptions={{ fit: "fill", cropFocus: "entropy" }}
                          
                          className="img"
                          lcayout="constrained"
                          src="../images/homepage/repogal.png"
                          // formats={["auto", "webp", "avif"]} 
                          />
                      <div>
                          <h1>Wyniki 2023</h1>
                      </div>
                    </ImageHeroStyle>
                  </AnimationOnScroll>
                  </ImageBoxStyled>
                <ImageBoxStyled>
                <AnimationOnScroll animateIn="animate__zoomIn" duration={1} delay={0.4} animateOnce={true}>

                    <ImageHeroStyle  href={Wyniki24} title="Wyniki URDB 2024" className='linkStyle'>
                      <StaticImage 
                          placeholder="blurred"
                          alt="more ultra race"
                          // transformOptions={{ fit: "fill", cropFocus: "entropy" }}
                          
                          className="img"
                          lcayout="constrained"
                          src="../images/homepage/repogal2.png"
                          // formats={["auto", "webp", "avif"]} 
                          />
                      <div>
                          <h1>Wyniki 2024</h1>
                      </div>
                    </ImageHeroStyle>
                  </AnimationOnScroll>
                  </ImageBoxStyled>
                </GalleryPage>

    </Layout>
  )
}



export default Galeria

export const Head = () => (
  <SEO title="Wyniki Ultra Race Dolina Bugu" description="Ultra Race Dolina Bugu to zawody gravelowe wdłuz jednej z ostanich dzikich rzek w Europie. Dwie trasy 300 i 550km"/>
)